import App from './App.vue'

export default [
    {
        path: '/:lang', 
        component: App,
    },
    {
        path: "*",
        redirect: "/en",
    }
]