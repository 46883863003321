import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n';
import messages from './lang';
import Routes from "./routes";

Vue.config.productionTip = false

Vue.use(VueI18n);
Vue.use(VueRouter);

const router = new VueRouter({
  routes: Routes
});

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
});

new Vue({
  i18n,
  render: function (h) { return h(App, {
    props: {
      siteLanguage: ""+ document.getElementById('app').attributes.siteLanguage.value,
      line1: ""+ document.getElementById('app').attributes.line1.value,
      line2: ""+ document.getElementById('app').attributes.line2.value,
      line3: ""+ document.getElementById('app').attributes.line3.value,
      line4: ""+ document.getElementById('app').attributes.line4.value,
      newsletterTag: ""+ document.getElementById('app').attributes.newsletterTag.value,
      bgColor: ""+ document.getElementById('app').attributes.bgColor.value,
      headingColor: ""+ document.getElementById('app').attributes.headingColor.value,
      sublineColor: ""+ document.getElementById('app').attributes.sublineColor.value,
      bulletPointColor: ""+ document.getElementById('app').attributes.bulletPointColor.value,
      checkBoxTextColor: ""+ document.getElementById('app').attributes.checkBoxTextColor.value,
      tNCLink: ""+ document.getElementById('app').attributes.tNCLink.value,
      btnVariant: ""+ document.getElementById('app').attributes.btnVariant.value,
      btnBgColor: ""+ document.getElementById('app').attributes.btnBgColor.value,
      btnTextColor: ""+ document.getElementById('app').attributes.btnTextColor.value,
    }
  })},
  router:router
}).$mount('#app')
